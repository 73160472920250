import React, { Component } from 'react';
import SvgLoading from '../../assets/images/loading.svg';
import '../../assets/css/loading.css';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <div className='g-loading'>
                <img src={SvgLoading} alt='' />
            </div>
        );
    }
}

export default Loading;