import React, { Component } from 'react';

class Job extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {

        const data = this.props.aboutInfo;

        return (
            <dl className="about-item">
                <dt>
                    JOB
                </dt>
                <dd dangerouslySetInnerHTML={{__html: data.info.job}}></dd>
            </dl>
        );
    }
}

export default Job;