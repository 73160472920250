import React from 'react';

import '../assets/css/about.css';
import Profile from '../components/about/Profile';
import Awards from '../components/about/Awards';
import Contact from '../components/about/Contact';
import Client from '../components/about/Client';
import Job from '../components/about/Job';
import Loading from '../components/utils/Loading';
import { getAbout } from '../service/GetData';

class About extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            aboutInfo: null
        }
    }

    render() {

        const aboutInfo = this.state.aboutInfo;

        return (
            <div>
                {
                    aboutInfo ?
                    <div>
                        <Profile aboutInfo={aboutInfo} />
                        <Awards aboutInfo={aboutInfo} />
                        <Client aboutInfo={aboutInfo} />
                        <Contact aboutInfo={aboutInfo} />
                        <Job aboutInfo={aboutInfo} />
                    </div>
                    : <Loading />
                }
            </div>
        )
    }

    componentDidMount () {
        let curPage = 'about';
        this.props.setTitle(curPage);
        this.getAbout();
    }

    getAbout() {
        let getObj = getAbout();

        getObj.then(( (res) => {
            this.setState({
                aboutInfo: res.data.data.list
            });
        }));

    }

}

export default About;