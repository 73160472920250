import React from 'react';
import { Link } from 'react-router-dom';
import Lazyload from 'react-lazyload';

import { getWorks } from '../service/GetData';
import LoadMore from '../components/home/LoadMore';

import '../assets/css/home.css';

class Home extends React.Component {

    constructor (props) {

        super(props)

        this.state = {
            isLoading: false,
            curPage: 0,
            total: 0,
            isFirstLoad: true,
            isLastPage: false,
            works: []
        }

    }


    render () {
        
        return (
            <div>
                <ul className="ct-works">
                    {
                        this.state.works.map((v, k) => {
                            
                            return (
                                <li key={k}>
                                    <Lazyload offset={50}>
                                        <Link className="lk-works" key={k} to={'/works/' + v.id}>
                                            <img src={v.logo} alt='' />
                                        </Link>
                                    </Lazyload>
                                </li>
                            )
                        })
                    }
                </ul>
                <LoadMore isLoading={this.state.isLoading} isLastPage={this.state.isLastPage} LoadMore={this.getWorksData.bind(this)} />
            </div>
        )

    }

    // 获取并处理数据
    getWorksData() {
        let getObj;
        const PAGE_SIZE = 20;
        let curPage = this.state.curPage;

        
        // 非首次加载且最后一页时不请求数据
        if(!this.state.isFirstLoad && curPage >= this.state.total) {
            this.setState({
                isLastPage: true
            });
            return ;
        }
        
        if(this.state.isLoading) {
            return ;
        }
        
        // 设置加载中状态
        this.setState({
            isLoading: true
        });


        getObj = getWorks({
            page: ++curPage,
            pagesize: PAGE_SIZE
        });

        getObj.then((res) => {
            const result = res.data.data.list;
            this.setState({
                works: this.state.works.concat(result.data),
                isFirstLoad: false,
                total: result.last_page,
                curPage: curPage,
                isLoading: false
            });
        });

    }


    componentDidMount () {
        let curPageName = 'works';
        this.props.setTitle(curPageName);
        this.getWorksData();
    }


}

export default Home;