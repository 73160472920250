import React, { Component } from 'react';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {

        const data = this.props.aboutInfo;

        return (
            <dl className="about-item">
                <dt>CONTACT</dt>
                <dd dangerouslySetInnerHTML={{__html: data.info.address}}></dd>
            </dl>
        );
    }
}

export default Contact;