import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import logoTitle from '../../assets/images/logo-title.svg';
import '../../assets/css/header.css';

class Header extends React.Component {
    constructor (props) {
        super(props);

        this.state = {}

    }

    render () {

        const _path = this.props.curPage === 'about' ? '/' : '/about';
        const _icon = this.props.curPage === 'about' ? 'nav-work' : 'icon';

        return(
            <header className="g-header">
                <div className="ct-main">
                    <Link to="/" className="ct-logo g-anim">
                        <img src={logo} alt="weestar studio" className="logo" />
                    </Link>
                    <Link to="/" className="ct-title">
                        <img src={logoTitle} alt="weestar studio" className="title" />
                    </Link>
                    <span className="slogan g-font-light">
                    INDEPENDENT DESIGN STUDIO SPECIALIZING <br />
                    IN GRAPHIC DESIGN AND ART DIRECTION.
                    </span>
                    <span className="contact g-font-light">
                        CONTACT US: <br />
                        INFO@WEESTAR.COM
                    </span>
                    <Link to={_path}>
                        <span className="btn-menu"><span className={_icon}></span></span>
                    </Link>
                </div>
                <div className="ct-about">
                    {
                        this.props.curPage === 'about'
                        ? <Link to="/" className="nav-work g-font-bold g-anim">WORK</Link>
                        : <Link to="/about" className="nav-about g-font-bold g-anim">ABOUT</Link>
                    }
                </div>
            </header>
        )
    }

}

export default Header;