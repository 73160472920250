import React, { Component } from 'react';


class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {

        const data = this.props.aboutInfo;

        return (
            <dl className="about-item">
                <dt>ABOUT</dt>
                <dd dangerouslySetInnerHTML={{__html:data.info.about}}></dd>
            </dl>
        );
    }
}

export default Profile;