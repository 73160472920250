import React from 'react';

import '../../assets/css/footer.css';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }

    render() {
        return (
            <div className="g-footer">
                2020 &copy; Weestar.Sudio<span className="beian">京ICP备129374799-1号</span>
            </div>       
        );
    }
}

export default Footer;