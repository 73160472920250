import React, { Component } from 'react';

class Client extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {

        const data = this.props.aboutInfo.client;

        return (
            <dl className="about-item">
                <dt>CLIENT</dt>
                <dd>
                    <ul className="client-li g-font-bold">
                        {
                            data.map( (v, k) => {
                               return (
                               <li key={k}>{v.title}</li>
                               ) 
                            })
                        }
                    </ul>
                </dd>
            </dl>
        );
    }


}

export default Client;