import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// 添加相关页面组件
// import App from '../App';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Home from '../pages/Home';
import Works from '../pages/Works';
import About from '../pages/About';
import NotFound from '../pages/404';

class RouterMap extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            title: 'weestar'
        }
    }


    render () {
        return (
            <BrowserRouter>
                <Header curPage={this.state.title} />
                <Switch>
                    <Route exact path='/' render={(props) => <Home setTitle={this.setTitle} {...props} /> } />
                    <Route path='/about' render={(props) => <About setTitle={this.setTitle} {...props} /> } />
                    <Route path='/Works/:id' render={(props) => <Works setTitle={this.setTitle} {...props} key={props.match.params.id} /> } />
                    <Route path='*' render={(props) => <NotFound setTitle={this.setTitle} {...props} /> } />
                </Switch>
                <Footer />
            </BrowserRouter>
        )
    }

    setTitle = (newTitle) => {
        this.setState({
            title: newTitle
        });
    }

    componentDidMount() {
        window.addEventListener("touchmove", function(event) {
            if(event.scale && event.scale !== 1) {
                event.preventDefault();
            }
        }, {
            passive: false
        });
    }

}

export default RouterMap;