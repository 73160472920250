import React from 'react';

import '../assets/css/notfound.css';
import svg404 from '../assets/images/404.png';

class NotFound extends React.Component {
    constructor (props) {
        super(props);

        this.state = {}

    }

    render() {
        return (
            <div className="notfound">
                <p className="pic"><img src={svg404} alt='' /></p>
                <p className="g-font-bold en">We can’t find the page.</p>
                <p>页面不存！</p>
            </div>
        )
    }

    componentDidMount() {

        // 5秒钟后跳回首页
        // const T = 5 * 1000;

        // setTimeout(() => {
        //     this.props.history.push('/');
        // }, T);

    }

}

export default NotFound;