import React, { Component } from 'react';

import Loading from '../../components/utils/Loading';

class LoadMore extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {

        return (
            <div className="load-more" ref='morewrap'>
                {
                    this.props.isLoading && !this.props.isLastPage
                    ? <Loading />
                    : null
                }
            </div>
        );
    }

    componentDidMount () {
        const eleMore = this.refs.morewrap;
        const loadMoreData = this.props.LoadMore;
        let timmer = null;

        function scrollCallback() {
            const top = eleMore.getBoundingClientRect().top;
            const viewPortHeight = document.documentElement.clientHeight;

            if(top && top < viewPortHeight) {
                loadMoreData();
            }
        }

        window.addEventListener('scroll', function() {

            // 如果正在加载中直接返回
            if(this.props.isLoading) {
                return ;
            }

            if(timmer) {
                clearTimeout(timmer);
                timmer = null;
            }

            timmer = setTimeout(scrollCallback, 50);


        }.bind(this), false);

    }


}

export default LoadMore;