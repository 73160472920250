import axios from 'axios';

let baseUrl;

const CONF = {
    // 测试环境
    'test' : '//wee.itechart.cn/',
    // 生产环境
    'prod' : '//super.weestar.com/',
    // 开发环境
    'dev'  : '//wee.itechart.cn/'
    // 'dev'  : '//super.weestar.com/'
}

baseUrl = CONF[process.env.REACT_APP_PUB_ENV] || '';

const REQ_URL = {
    WORKS: '/api/articles/gets',
    DETAIL: '/api/articles/get',
    ABOUT: '/api/index/about'
}


axios.defaults.baseURL = baseUrl;

// 获取作品列表
export function getWorks(params) {
    return axios.get(REQ_URL.WORKS, {
        params: params
    });
}

// 获取作品详细
export function getWorksDetail(id) {
    return axios.get(REQ_URL.DETAIL, {
        params: {
            id: id
        }
    });
}

// 获取about信息
export function getAbout() {
    return axios.get(REQ_URL.ABOUT);
}