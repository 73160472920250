import React, { Component } from 'react';

class Awards extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {

        const data = this.props.aboutInfo.awards;

        return (
            <dl className="about-item">
                <dt>
                AWARDS<br />
                HONORS
                </dt>
                <dd className="awards-list">
                    <ul className="en-list g-font-bold">
                        {
                            data.map((v, k) => {
                                return (
                                    <li key={k}>
                                        <span className="date">{v.time}</span><span className="awards">{v.title_en}</span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <ul className="zh-list">
                        {
                            data.map((v, k) => {
                                return (
                                    <li key={k}>
                                        <span className="date">{v.time}</span><span className="awards">{v.title}</span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </dd>
            </dl>
        );
    }

}

export default Awards;