import React from 'react';
import { Link } from 'react-router-dom';
import Lazyload from 'react-lazyload';
import { getWorksDetail } from '../service/GetData';
import '../assets/css/works.css';
import Loading from '../components/utils/Loading';

// 重新处理用户
function Contributor(props) {
    const roles = props.roles.split(',');
    const len = roles.length - 1;
    return  roles.map((v, k) => {
        return (
            <span key={k}>
                <em>
                    {v}
                    {k == len ? '': ','}
                </em>
            </span>
        )
    })
}

class Works extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            workInfo: null
         };
    }


    render() {

        var workInfo = this.state.workInfo;
        let prevId,
            nextId;

        if(workInfo) {
            prevId = workInfo.preid;
            nextId = workInfo.nextid;
        }

        return (
            <div>
                {
                    workInfo === null ? <Loading />:
                    <div>
                        <section className="works-desc">
                            <div className="nav">
                                <div className="inner">
                                    {
                                        prevId
                                        ? <Link to={'/works/' + prevId} className="prev"></Link>
                                        : <span className="prev disabled"></span>
                                    }
                                    <Link to="/" className="home"></Link>
                                    {
                                        nextId
                                        ? <Link to={'/works/' + nextId} className="next"></Link>
                                        : <span className="next disabled"></span>
                                    }
                                </div>
                            </div>

                            <div className="pro-wrapper">
                                <div className="main">
                                    <div className="profile">
                                        <h2 className="title">
                                            <span className="title-en g-font-bold">{workInfo.title_en}</span>
                                            {
                                                workInfo.title
                                                ? <span className="split">/</span>
                                                :  null
                                            }
                                            {workInfo.title}
                                        </h2>
                                        <div dangerouslySetInnerHTML={{__html: workInfo.content}}></div>
                                    </div>

                                    <div className="contributor">
                                        <ul>
                                            {
                                                workInfo.roles.map((v, k) => {
                                                    return (
                                                        <li key={k}>
                                                            <span className="cont-title g-font-bold">{v.roletitle}:</span>
                                                            <Contributor roles={v.rolename} />
                                                        </li>
                                                    )
                                                })
                                            }
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="picture">
                            {this.filterImg(workInfo.image)}
                        </section>
                    </div>
                }
                
            </div>
        );
    }

    componentDidMount () {
        let curPage = 'works';
        this.props.setTitle(curPage);
        this.getDetial();
    }

    getDetial () {
        let workId = this.props.match.params.id;
        let getObj = getWorksDetail(workId);

        getObj.then((res) => {
            if(res.data.code) {
                this.setState({
                    workInfo: res.data.data
                });
            } else {
                this.props.history.push('/');
            }
        });
    }

    filterImg (result) {
        const REG_PIC_EXT = /(.*)\.(jpg|bmp|gif|ico|pcx|jpeg|tif|png|raw|tga)+$/;
        const REG_V_EXT = /(.*)\.(mp4|flv|mpg|webm|ogg)+$/;

        return result.map((v, k) => {
            // 如果是图片
            if(REG_PIC_EXT.test(v)) {
                return (
                    <div key={k}>
                        <Lazyload height={300}>
                            <img src={v} alt='' />
                        </Lazyload>
                    </div>
                )
            }
            // 如果是视频
            else if(REG_V_EXT.test(v)) {
                return (
                    <div key={k}>
                        <Lazyload height={300}>
                            <video loop="loop" controls="controls">
                                <source src={v}></source>
                            </video>
                        </Lazyload>
                    </div>
                )
            }
        })
    }


}

export default Works;